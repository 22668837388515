import { createTheme, Theme } from '@mui/material/styles';
import { themes } from './Icons';

const getCssVariableValue = (variable: string): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
};

const getTheme = (darkMode: boolean): Theme => {
  const themeMode = darkMode ? themes.dark : themes.light;
  const primaryMainColor = darkMode

    ? getCssVariableValue('--boton-800-primario') || '#6CD39B' : themeMode.colors.Activo; // CAMBIA EL COLOR DE LOS COMPOENTES DE color

  return createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      background: {
        default: darkMode ? '#121212' : '#FFFFFF', // CONTENIDO CAMBIAR COLOR 
        paper: darkMode ? '#121212' : '#FFFFFF', //CAMBIA COLOR A MODALES
      },
      text: {
        primary: darkMode ? '#E7EFFC' : '#1E2947', // TEXTO PRIMARIO CAMBIA COLOR Naranja y COLOR AZUL DE LOS TEXTO
        secondary: darkMode ? '#A6E5C3' : '#88919E', //TEXTO SECUNDARIO CAMBIA COLOR Gris 
      },
      primary: {
        main: primaryMainColor,
      },
      secondary: {
        main: darkMode ? '#79DD8B' : '#FFF',
      },
    
    },
    typography: {
      fontFamily: ['Montserrat', 'Poppins','Helvetica'].join(','), // cambiar los textos 
    },

    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: darkMode ? '#E7EFFC' : '#1E2947', // Aquí sobrescribes el color de los inputs
          },
        },
      },
    },

  });
};

export default getTheme;
