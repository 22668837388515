
import { Suspense, lazy, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import getTheme from "../config/theme";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { SidebarProvider } from "../contexts/SidebarContext";
import PublicRoute from "../authenticate/publicRoutes";
import ProtectedRoute from "../authenticate/protectedRoutes";
import { UserProvider } from "../contexts/UserContext";


const Login = lazy(() => import("../pages/Login"));
const Cuenta = lazy(() => import("../content/cuentas/DeudaProveedor"));
const Ordenes = lazy(() => import("../content/cuentas/OrdenesPago"));
const Dashboard = lazy(() => import("../content/Dashboard/Dashboard"));
const Inicio = lazy(() => import("../content/inicio/Inicio"));

const Ruta = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = useMemo(() => getTheme(darkMode), [darkMode]);

  const handleThemeChange = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <Suspense fallback={<></>}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <UserProvider>
            <SidebarProvider>
            <Routes>
              
                <Route path="/" element={
                  <PublicRoute>       
                    <Login isDarkMode={darkMode} />
                  </PublicRoute>
                } />
              
                 
                <Route path="/menu" element={
                  <ProtectedRoute>
                    <Dashboard isDarkMode={darkMode} handleThemeChange={handleThemeChange}/>
                  </ProtectedRoute>}>
                  {/* rutas hijas */}
                  <Route path="/menu/inicio" element={<Inicio isDarkMode={darkMode}/>} />
                  <Route path="/menu/cuentas" element={<Cuenta isDarkMode={darkMode}/>} />
                  <Route path="/menu/ordenes-de-pago" element={<Ordenes isDarkMode={darkMode}/>} />
                </Route>
                
            </Routes>
            </SidebarProvider>
          </UserProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  );
};

export default Ruta;
