import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SelectModel } from "../models/SelectModel";
import LoaderScreen from "../components/loaders/LoaderScreen";

export const UserContext = createContext<any>(null);

export const UserProvider = (props: any) => {
  const navigate = useNavigate();
  const [tokenUser, setTokenUser] = useState<string | null>(() => {  return localStorage.getItem("token_session"); });
  const [ruta, setRuta] = useState<string | null>(() => { return localStorage.getItem("ruta"); });
  const [imageFondo, setImageFondo] = useState<string | null>(() => {   return localStorage.getItem("Fondo_ImageUrl"); }); // Carga desde localStorage
  const [loading, setLoading] = useState(false); //Loading
  
  const [selectLocales, setSelectLocales] = useState<SelectModel[]>(() => {
    // Verifica si hay datos en localStorage y parsea el JSON, de lo contrario, usa un array vacío
    const savedLocales = localStorage.getItem("selectLocales");    
    return savedLocales ? JSON.parse(savedLocales) : []; 
  });

  // Función para actualizar la imagen de fondo
  const setImageUrl = (url: string) => {
    localStorage.setItem("Fondo_ImageUrl", url);
    setImageFondo(url);
  };

  const idTokenUser = (token: string) => {
    if (token === null || token === undefined) {
      localStorage.removeItem("token_session");
      setTokenUser(null);
      idLoading(false);
      return navigate("/");
    }
    localStorage.setItem("token_session", token);
    setTokenUser(token);
  };

  const idRuta = (idRuta: any) => {
    if (idRuta === null || idRuta === undefined) {
      localStorage.removeItem("token");
      localStorage.removeItem("ruta");
      setRuta(null);
      idLoading(false);
      return navigate("/");
    }

    localStorage.setItem("ruta", idRuta);
    setRuta(idRuta!);
  };

  //Funcion para cargargar el Loading
  const idLoading = (idLoading: boolean) => {
    setLoading(idLoading);
  };

  const idSelectLocales = (idSelectedLocales: any) => {
    localStorage.setItem("selectLocales", JSON.stringify(idSelectedLocales));
    setSelectLocales(idSelectedLocales); 
  };

  return (
    <UserContext.Provider
      value={{
        tokenUser,
        idTokenUser,
        ruta,
        idRuta,
        imageFondo, // Proveer el valor de la imagen
        setImageUrl, // Proveer la función para actualizar la imagen
        idLoading,
        selectLocales,
        idSelectLocales
      }}
    >
      {props.children}
      {loading && <LoaderScreen />} {/*recarga el componete para el contexto */}
    </UserContext.Provider>
  );
};
