//LOGIN
import ContrasenaPasivo from "../assets/Icons/LOGIN/CONTRASENA_PASIVO_24X24.svg";
import ContrasenaActivo from "../assets/Icons/LOGIN/CONTRASENA_ACTIVO_24X24.svg";
import UsuarioActivo from "../assets/Icons/LOGIN/USUARIO_ACTIVO_24X24.svg";
import UsuarioPasivo from "../assets/Icons/LOGIN/USUARIO_PASIVO_24X24.svg";
import OjoMostrar from "../assets/Icons/LOGIN/OJO_MOSTRAR_24X24.svg";
import OjoOcultar from "../assets/Icons/LOGIN/OJO_OCULTAR_24X24.svg";
import Alerta from "../assets/Icons/LOGIN/ALERTA_24X24.svg";

//BUSQUEDA
import BusquedaActivo from "../assets/Icons/BUSQUEDA/BUSCAR_ACTIVO_24X24.svg";
import BusquedaPasivo from "../assets/Icons/BUSQUEDA/BUSCAR_PASIVO_24X24.svg";
import CerrarActivo from "../assets/Icons/BUSQUEDA/CERRAR_ACTIVO_24X24.svg";
import CerrarHover from "../assets/Icons/BUSQUEDA/CERRAR_HOVER_24X24.svg";
import HistorialActivo from "../assets/Icons/BUSQUEDA/HISTORIAL_ACTIVO_24X24.svg";
import Interrogante from "../assets/Icons/BUSQUEDA/INTERROGANTE_CHECK_20X20.svg";
import ExportarActivo from "../assets/Icons/BUSQUEDA/EXPORTAR_ACTIVO_24X24.svg";
import ExportarPasivo from "../assets/Icons/BUSQUEDA/EXPORTAR_PASIVO_24X24.svg";

//lOGOS
import LogoINFOBACK from "../assets/Logos/LOGO_INFOBACK_130X24.svg";
import LogoMovilINFOBACK from "../assets/Logos/LOGO_INFOBACK_MOVIL_33.85X36.svg";
import LogoAppMovil from "../assets/Logos/LOGO_PROVISIONAL_MOVIL_180X140.svg";
import LogoAppPC from "../assets/Logos/LOGO_PROVISIONAL_PC_206x160.svg";
import logoInfomatica from "../assets/Logos/LOGO_INFOMATICA_180X66.002.svg";
import LogoInfomaticaPasivo from "../assets/Logos/LOGO_INFOMATICA_PASIVO_200X70.svg";
import LogoInfomaticaActivo from "../assets/Logos/LOGO_INFOMATICA_ACTIVO_200X70.svg";
import LogoPowered from "../assets/Logos/POWEREDBY_132X28.svg";
import LogoRestauranes from "../assets/Logos/LOGO_STOCK_52X52.svg";
//IMAGES
import AnuncioBiMovil360x360 from "../assets/Images/ANUNCIO_BI_MOVIL_360X360.jpg";
import AnuncioBiMovil320x320 from "../assets/Images/ANUNCIO_BI_MOVIL_320X320.jpg";
import AnuncioBiPC640x280 from "../assets/Images/ANUNCIO_BI_PC_640X280.png";
import AnuncioBiPC680x280 from "../assets/Images/ANUNCIO_BI_PC_680X280.jpg";
import AnuncioCertiINFOFACT from "../assets/Images/ANUNCIO_CERTIFICADO_INFOFACT_640X280.png";
import AnuncioINFOFACTMovil320x20 from "../assets/Images/ANUNCIO_ZOHO_INFOFACT_MOVIL_320X320.png";
import AnuncioINFOFACTPC640x280 from "../assets/Images/ANUNCIO_ZOHO_INFOFACT_PC_640X280.png";
import FondoImagen from "../assets/Images/FONDO_INFOFACT.webp";
import Usuario from "../assets/Images/USUARIO_60X60.jpg";

//SIDEBAR
import ComprarActivo from "../assets/Icons/SIDEBAR/SIDEBAR_COMPRAS_ACTIVO_68X56.svg";
import ComprasHover from "../assets/Icons/SIDEBAR/SIDEBAR_COMPRAS_HOVER_68X56.svg";
import ComprasPasivo from "../assets/Icons/SIDEBAR/SIDEBAR_COMPRAS_PASIVO_68X56.svg";
import CuentaActivo from "../assets/Icons/SIDEBAR/SIDEBAR_CUENTAS_ACTIVO_68X56.svg";
import CuentasHover from "../assets/Icons/SIDEBAR/SIDEBAR_CUENTAS_HOVER_68X56.svg";
import CuentasPasivo from "../assets/Icons/SIDEBAR/SIDEBAR_CUENTAS_PASIVO_68X56.svg";
import DocActivo from "../assets/Icons/SIDEBAR/SIDEBAR_DOC_ACTIVO_68X56.svg";
import DocHover from "../assets/Icons/SIDEBAR/SIDEBAR_DOC_HOVER_68X56.svg";
import DocPasivo from "../assets/Icons/SIDEBAR/SIDEBAR_DOC_PASIVO_68X56.svg";
import InicioActivo from "../assets/Icons/SIDEBAR/SIDEBAR_INICIO_ACTIVO_68X56.svg";
import InicioHover from "../assets/Icons/SIDEBAR/SIDEBAR_INICIO_HOVER_68X56.svg";
import InicioPasivo from "../assets/Icons/SIDEBAR/SIDEBAR_INICIO_PASIVO_68X56.svg";
import KardexActivo from "../assets/Icons/SIDEBAR/SIDEBAR_KARDEX_ACTIVO_68X56.svg";
import KardexHover from "../assets/Icons/SIDEBAR/SIDEBAR_KARDEX_HOVER_68X56.svg";
import KardexPasivo from "../assets/Icons/SIDEBAR/SIDEBAR_KARDEX_PASIVO_68X56.svg";
import ListadoActivo from "../assets/Icons/SIDEBAR/SIDEBAR_LISTADO_ACTIVO_68X56.svg";
import ListadoHover from "../assets/Icons/SIDEBAR/SIDEBAR_LISTADO_HOVER_68X56.svg";
import ListadoPasivo from "../assets/Icons/SIDEBAR/SIDEBAR_LISTADO_PASIVO_68X56.svg";
import ProcesosActivo from "../assets/Icons/SIDEBAR/SIDEBAR_PROCESOS_ACTIVO_68X56.svg";
import ProcesoHover from "../assets/Icons/SIDEBAR/SIDEBAR_PROCESOS_HOVER_68X56.svg";
import ProcesosPasivo from "../assets/Icons/SIDEBAR/SIDEBAR_PROCESOS_PASIVO_68X56.svg";

//FOOTER
import PoweredBy from "../assets/Icons/FOOTER/POWERED_BY_131x28.svg";
import Facebook from "../assets/Icons/FOOTER/RSFacebook.svg";
import FacebookHover from "../assets/Icons/FOOTER/RSFacebookHOVER.svg";
import Instagram from "../assets/Icons/FOOTER/RSInstagram.svg";
import InstagramHover from "../assets/Icons/FOOTER/RSInstagramHOVER.svg";
import Linkedin from "../assets/Icons/FOOTER/RSLinkedin.svg";
import LinkedinHover from "../assets/Icons/FOOTER/RSLinkedinHOVER.svg";
import TikTok from "../assets/Icons/FOOTER/RSTikTok.svg";
import TikTokHover from "../assets/Icons/FOOTER/RSTikTokHOVER.svg";

//HEADER
import CerrarSesionActivo from "../assets/Icons/HEADER/CERRAR_SESION_ACTIVO_24X24.svg";
import CerrarSesionPasivo from "../assets/Icons/HEADER/CERRAR_SESION_PASIVO_24X24.svg";
import ConfiguracionActivo from "../assets/Icons/HEADER/CONFIGURACION_ACTIVO_24X24.svg";
import ConfiguracionPasivo from "../assets/Icons/HEADER/CONFIGURACION_PASIVO_24X24.svg";

import FlechaArribaPasivo from "../assets/Icons/HEADER/FLECHA_ARRIBA_PASIVO_24X24.svg";
import FlechaArribaActivo from "../assets/Icons/HEADER/FLECHA_ARRIBA_ACTIVO_24X24.svg";

import FlechaAbajoActivo from "../assets/Icons/HEADER/FLECHA_ABAJO_ACTIVO_24X24.svg";
import FlechaAbajoPasivo from "../assets/Icons/HEADER/FLECHA_ABAJO_PASIVO_24X24.svg";

import CheckActivo from "../assets/Icons/HEADER/CHECK_ACTIVO_24X24.svg";
import CheckPasivo from "../assets/Icons/HEADER/CHECK_PASIVO_24X24.svg";
import IntercambiarPasivo from "../assets/Icons/HEADER/INTERCAMBIAR_PASIVO_24X24.svg";
import IntercambiarActivo from "../assets/Icons/HEADER/INTERCAMBIAR_ACTIVO_24X24.svg";
import MenuCategoriaActivo from "../assets/Icons/HEADER/MENU_CATEGORIA_ACTIVO_24X24.svg";
import MenuCategoriaPasivo from "../assets/Icons/HEADER/MENU_CATEGORIA_PASIVO_24X24.svg";
import Español from "../assets/Icons/HEADER/IDIOMA_ESPAÑA_32X32.svg";
import Ingles from "../assets/Icons/HEADER/IDIOMA_INGLES_32X32.svg";

//DASHBOARD
import FlechaDerechaActivo from "../assets/Icons/DASHBOARD/FLECHA_DERECHA_ACTIVO_22X22.svg";
import FlechaDerechaPasivo from "../assets/Icons/DASHBOARD/FLECHA_DERECHA_PASIVO_22X22.svg";
import FlechaIzquierdaActivo from "../assets/Icons/DASHBOARD/FLECHA_IZQUIERDA_ACTIVO_22X22.svg";
import FlechaIzquierdaAPasivo from "../assets/Icons/DASHBOARD/FLECHA_IZQUIERDA_PASIVO_22X22.svg";
import DocumentoActivo from "../assets/Icons/DASHBOARD/DOCUMENTO_ACTIVO_36X36.svg";
import FlechaLinealActivo from "../assets/Icons/DASHBOARD/FLECHA_LINEAL_ACTIVO_24X24.svg";
import FlechaLinealPasivo from "../assets/Icons/DASHBOARD/FLECHA_LINEAL_PASIVO_24X24.svg";

//LOTTIES
import AlertaLotties from "../assets/Lotties/ALERTA_320X320.json";
import CargaVerdeLotties from "../assets/Lotties/CARGA_VERDE_200X200.json";
import ErrorLotties from "../assets/Lotties/ERROR_320X320.json";
import PosLotties from "../assets/Lotties/POS_640X640.json";
import ExitoLotties from "../assets/Lotties/EXITO_600X600.json";

//TABLAS
import FlechablacoAbajo from "../assets/Icons/TABLAS/FLECHA_ORDENAR_BLANCO_ABAJO_16X16.svg";
import FlechaBlancoArriba from "../assets/Icons/TABLAS/FLECHA_ORDENAR_BLANCO_ARRIBA_16X16.svg";
import FlechaBlnacoPasivo from "../assets/Icons/TABLAS/FLECHA_ORDENAR_BLANCO_PASIVO_16X16.svg";
import FlechaNarangaAbajo from "../assets/Icons/TABLAS/FLECHA_ORDENAR_NARANJA_ABAJO_16X16.svg";
import FlechaNarangaArriba from "../assets/Icons/TABLAS/FLECHA_ORDENAR_NARANJA_ARRIBA_16X16.svg";
import FlechaNarangaPasivo from "../assets/Icons/TABLAS/FLECHA_ORDENAR_NARANJA_PASIVO_16X16.svg";
import FlechaOrdenes from "../assets/Icons/TABLAS/FLECHA_ORDENES_24X24.svg";
import MenuNarangaActivo from "../assets/Icons/TABLAS/MENU_ORDENES_TRES_PUNTOS_24X24.svg";

//MODALES
import docojo from "../assets/Icons/MODALES/ABRIR_OJO_DOC_24X24.svg";
import CalendarioActivo from "../assets/Icons/MODALES/CALENDARIO_ACTIVO_24X24.svg";
import CalendarioInactivo from "../assets/Icons/MODALES/CALENDARIO_INACTIVO_24X24.svg";
import CopyrightActivo from "../assets/Icons/MODALES/COPYRIGHT_ACTIVO_24X24.svg";
import CopyrightPasivo from "../assets/Icons/MODALES/COPYRIGHT_PASIVO_24X24.svg";
import FlechaCerrar from "../assets/Icons/MODALES/FLECHA_CERRAR_44X36.svg";
import FlechaActivo from "../assets/Icons/MODALES/FLECHA_ACTIVO_24X24.svg";
import FlechaInactivo from "../assets/Icons/MODALES/FLECHA_INACTIVO_24X24.svg";
import PagoActivo from "../assets/Icons/MODALES/PAGO_ACTIVO_24X24.svg";
import PagoListo from "../assets/Icons/MODALES/PAGO_LISTO_24X24.svg";
import FlechaPequeñaActiva from "../assets/Icons/MODALES/FELCHA_ACTIVA_PEQUEÑA_10X6.svg";
import FlechaPequeñaHover from "../assets/Icons/MODALES/FELCHA_HOVER_PEQUEÑA_10X6.svg";
import CheckboxPasivo from "../assets/Icons/MODALES/CHECKBOX_WHILE_24X24.svg";
import HuellaHover from "../assets/Icons/MODALES/HUELLA_HOVER_28X28.svg";
import HuellaPasivo from "../assets/Icons/MODALES/HUELLA_PASIVO_28X28.svg";
import Atiempo from "../assets/Icons/MODALES/A_TIEMPO_280X280.svg";
import FlechaGris from "../assets/Icons/MODALES/FLECHA_GRIS_FECHA_24X24.svg";
import EditarActivo from "../assets/Icons/MODALES/EDITAR_ACTIVO_24X24.svg";
import DolarComercio from "../assets/Icons/MODALES/DOLAR_COMERCIO_ACTIVO_24X24.svg";
import CheckXCuadro from "../assets/Icons/MODALES/CHECK_X_CUADRO_24X24.svg";
import Check from "../assets/Icons/MODALES/CHECK_ACTIVO_24X24.svg";
import UsuarioCliente from "../assets/Icons/MODALES/USUARIO_CLIENTES_24X24.svg";
import FlechaIzquierda from "../assets/Icons/MODALES/FLECHA_IZQUIERDA_ACTIVO.svg";

const lightIcons = {
  //ACTIVO
  contrasenaActivo: ContrasenaActivo,
  usuarioActivo: UsuarioActivo,
  busquedaActivo: BusquedaActivo,
  cerrarActivo: CerrarActivo,
  historialActivo: HistorialActivo,
  ComprarActivo: ComprarActivo,
  CuentaActivo: CuentaActivo,
  DocActivo: DocActivo,
  InicioActivo: InicioActivo,
  KardexActivo: KardexActivo,
  ListadoActivo: ListadoActivo,
  ProcesosActivo: ProcesosActivo,
  flechaLinealActivo: FlechaLinealActivo,
  exportarActivo: ExportarActivo,

  //PASIVO
  ContrasenaPasivo: ContrasenaPasivo,
  usuarioPasivo: UsuarioPasivo,
  busquedaPasivo: BusquedaPasivo,
  ComprasPasivo: ComprasPasivo,
  CuentasPasivo: CuentasPasivo,
  DocPasivo: DocPasivo,
  InicioPasivo: InicioPasivo,
  KardexPasivo: KardexPasivo,
  ListadoPasivo: ListadoPasivo,
  ProcesosPasivo: ProcesosPasivo,
  flechaLinealPasivo: FlechaLinealPasivo,
  exportarPasivo: ExportarPasivo,

  //HOVER
  InicioHover: InicioHover,
  KardexHover: KardexHover,
  ListadoHover: ListadoHover,
  ProcesoHover: ProcesoHover,
  ComprasHover: ComprasHover,
  CuentasHover: CuentasHover,
  DocHover: DocHover,
  cerrarHover: CerrarHover,

  //ICONOS
  OjoMostrar: OjoMostrar,
  ojoOcultar: OjoOcultar,
  alerta: Alerta,

  //FOOTER
  PoweredBy: PoweredBy,
  Facebook: Facebook,
  FacebookHover: FacebookHover,
  Instagram: Instagram,
  InstagramHover: InstagramHover,
  Linkedin: Linkedin,
  LinkedinHover: LinkedinHover,
  TikTok: TikTok,
  TikTokHover: TikTokHover,

  //HEADER
  cerrarSesionActivo: CerrarSesionActivo,
  cerrarSesionPasivo: CerrarSesionPasivo,
  configuracionActivo: ConfiguracionActivo,
  configuracionPasivo: ConfiguracionPasivo,
  flechaArribaActivo: FlechaArribaActivo,
  flechaArribaPasivo: FlechaArribaPasivo,
  flechaAbajoActivo: FlechaAbajoActivo,
  flechaAbajoPasivo: FlechaAbajoPasivo,
  checkActivo: CheckActivo,
  checkPasivo: CheckPasivo,
  intercambiarPasivo: IntercambiarPasivo,
  intercambiarActivo: IntercambiarActivo,
  menuCategoriaActivo: MenuCategoriaActivo,
  menuCategoriaPasivo: MenuCategoriaPasivo,
  español: Español,
  ingles: Ingles,

  //Dashboard
  flechaDerechaActivo: FlechaDerechaActivo,
  flechaDerechaPasivo: FlechaDerechaPasivo,
  flechaIzquierdaActivo: FlechaIzquierdaActivo,
  flechaIzquierdaAPasivo: FlechaIzquierdaAPasivo,
  documentoActivo: DocumentoActivo,

  //TABLAS
  flechablacoAbajo: FlechablacoAbajo,
  flechaBlancoArriba: FlechaBlancoArriba,
  flechaBlnacoPasivo: FlechaBlnacoPasivo,
  flechaNarangaAbajo: FlechaNarangaAbajo,
  flechaNarangaArriba: FlechaNarangaArriba,
  flechaNarangaPasivo: FlechaNarangaPasivo,
  interrogante: Interrogante,
  flechaOrdenes: FlechaOrdenes,
  menuNarangaActivo: MenuNarangaActivo,

  //MODALES
  docojo: docojo,
  calendarioActivo: CalendarioActivo,
  calendarioInactivo: CalendarioInactivo,
  copyrightActivo: CopyrightActivo,
  copyrightPasivo: CopyrightPasivo,
  flechaCerrar: FlechaCerrar,
  flechaActivo: FlechaActivo,
  flechaInactivo: FlechaInactivo,
  pagoActivo: PagoActivo,
  pagoListo: PagoListo,
  flechaPequeñaActiva: FlechaPequeñaActiva,
  flechaPequeñaHover: FlechaPequeñaHover,
  checkboxPasivo: CheckboxPasivo,
  huellaHover: HuellaHover,
  huellaPasivo: HuellaPasivo,
  Atiempo: Atiempo,
  flechaGris: FlechaGris,
  editarActivo: EditarActivo,
  dolarComercio: DolarComercio,
  checkXCuadro: CheckXCuadro,
  check: Check,
  usuarioCliente: UsuarioCliente,
  flechaIzquierda: FlechaIzquierda,
};

const darkIcons = {
  //ACTIVO
  contrasenaActivo: ContrasenaActivo,
  usuarioActivo: UsuarioActivo,
  busquedaActivo: BusquedaActivo,
  cerrarActivo: CerrarActivo,
  historialActivo: HistorialActivo,
  ComprarActivo: ComprarActivo,
  CuentaActivo: CuentaActivo,
  DocActivo: DocActivo,
  InicioActivo: InicioActivo,
  KardexActivo: KardexActivo,
  ListadoActivo: ListadoActivo,
  ProcesosActivo: ProcesosActivo,
  flechaLinealActivo: FlechaLinealActivo,
  exportarActivo: ExportarActivo,

  //PASIVO
  ContrasenaPasivo: ContrasenaPasivo,
  usuarioPasivo: UsuarioPasivo,
  busquedaPasivo: BusquedaPasivo,
  ComprasPasivo: ComprasPasivo,
  CuentasPasivo: CuentasPasivo,
  DocPasivo: DocPasivo,
  InicioPasivo: InicioPasivo,
  KardexPasivo: KardexPasivo,
  ListadoPasivo: ListadoPasivo,
  ProcesosPasivo: ProcesosPasivo,
  flechaLinealPasivo: FlechaLinealPasivo,
  exportarPasivo: ExportarPasivo,

  //HOVER
  InicioHover: InicioHover,
  KardexHover: KardexHover,
  ListadoHover: ListadoHover,
  ProcesoHover: ProcesoHover,
  ComprasHover: ComprasHover,
  CuentasHover: CuentasHover,
  DocHover: DocHover,
  cerrarHover: CerrarHover,

  //ICONOS
  OjoMostrar: OjoMostrar,
  ojoOcultar: OjoOcultar,
  alerta: Alerta,

  //FOOTER
  PoweredBy: PoweredBy,
  Facebook: Facebook,
  FacebookHover: FacebookHover,
  Instagram: Instagram,
  InstagramHover: InstagramHover,
  Linkedin: Linkedin,
  LinkedinHover: LinkedinHover,
  TikTok: TikTok,
  TikTokHover: TikTokHover,

  //HEADER
  cerrarSesionActivo: CerrarSesionActivo,
  cerrarSesionPasivo: CerrarSesionPasivo,
  configuracionActivo: ConfiguracionActivo,
  configuracionPasivo: ConfiguracionPasivo,
  flechaArribaActivo: FlechaArribaActivo,
  flechaArribaPasivo: FlechaArribaPasivo,
  flechaAbajoActivo: FlechaAbajoActivo,
  flechaAbajoPasivo: FlechaAbajoPasivo,
  checkActivo: CheckActivo,
  checkPasivo: CheckPasivo,
  intercambiarPasivo: IntercambiarPasivo,
  intercambiarActivo: IntercambiarActivo,
  menuCategoriaActivo: MenuCategoriaActivo,
  menuCategoriaPasivo: MenuCategoriaPasivo,
  español: Español,
  ingles: Ingles,

  //Dashboard
  flechaDerechaActivo: FlechaDerechaActivo,
  flechaDerechaPasivo: FlechaDerechaPasivo,
  flechaIzquierdaActivo: FlechaIzquierdaActivo,
  flechaIzquierdaAPasivo: FlechaIzquierdaAPasivo,
  documentoActivo: DocumentoActivo,

  //TABLAS
  flechablacoAbajo: FlechablacoAbajo,
  flechaBlancoArriba: FlechaBlancoArriba,
  flechaBlnacoPasivo: FlechaBlnacoPasivo,
  flechaNarangaAbajo: FlechaNarangaAbajo,
  flechaNarangaArriba: FlechaNarangaArriba,
  flechaNarangaPasivo: FlechaNarangaPasivo,
  interrogante: Interrogante,
  flechaOrdenes: FlechaOrdenes,
  menuNarangaActivo: MenuNarangaActivo,

  //MODALES
  docojo: docojo,
  calendarioActivo: CalendarioActivo,
  calendarioInactivo: CalendarioInactivo,
  copyrightActivo: CopyrightActivo,
  copyrightPasivo: CopyrightPasivo,
  flechaCerrar: FlechaCerrar,
  flechaActivo: FlechaActivo,
  flechaInactivo: FlechaInactivo,
  pagoActivo: PagoActivo,
  pagoListo: PagoListo,
  flechaPequeñaActiva: FlechaPequeñaActiva,
  flechaPequeñaHover: FlechaPequeñaHover,
  checkboxPasivo: CheckboxPasivo,
  huellaHover: HuellaHover,
  huellaPasivo: HuellaPasivo,
  Atiempo: Atiempo,
  flechaGris: FlechaGris,
  editarActivo: EditarActivo,
  dolarComercio: DolarComercio,
  checkXCuadro: CheckXCuadro,
  check: Check,
  usuarioCliente: UsuarioCliente,
  flechaIzquierda: FlechaIzquierda,
};

const LottiesIcons = {
  alertas: AlertaLotties,
  cargaVerde: CargaVerdeLotties,
  error: ErrorLotties,
  pos: PosLotties,
  exito: ExitoLotties,
};

const LogoIcons = {
  logoInfoBack: LogoINFOBACK,
  logoAppMovil: LogoAppMovil,
  logoAppPc: LogoAppPC,
  logoInfomatica: logoInfomatica,
  logoInfomaticaPasivo: LogoInfomaticaPasivo,
  logoInfomaticaActivo: LogoInfomaticaActivo,
  logoMovilINFOBACK: LogoMovilINFOBACK,
  logoPowered: LogoPowered,
  logoRestauranes: LogoRestauranes,
};

const Imagen = {
  anuncioBiMovil320x320: AnuncioBiMovil320x320,
  anuncioBiMovil360x360: AnuncioBiMovil360x360,
  anuncioBiPC640x280: AnuncioBiPC640x280,
  anuncioBiPC680x280: AnuncioBiPC680x280,
  anuncioCertiINFOFACT: AnuncioCertiINFOFACT,
  anuncioINFOFACTMovil320x20: AnuncioINFOFACTMovil320x20,
  anuncioINFOFACTPC640x280: AnuncioINFOFACTPC640x280,
  fondoImagen: FondoImagen,
  usuario: Usuario,
};

const lightColors = {
  Pasivo: "#FFFFFF",
  Hover: "#F7A43F",
  // Hover: "#79DD8B", //verde Hover
  Activo: "#F07B43",
  //Activo: "#42C67E",//verde
  //Gris
  Neutro: "#88919E",
  TitleGris: "#717883",
  //colores claros
  ClaroHover: "#FFFAF7",
  Claro: "#FFF5F0",
  //Alertas
  ErrorPasivo: "#FF655A",
  ErrorHover: "#F44336",
  ErrorHoverClaro: "#FFECEB",
  Exito: "#42C67E",
  Informacion: "#2D94B4",
  //texto
  Title: "#E46525",
  TitleHover: "#FCA422",
  //lINEA
  lineaModal: "#F5A458",
  lineaTabla: "#FBD6C5",
  //circulos
  Circular: "#FEEBE2",
};

const darkColors = {
  Pasivo: "#121212",
  Hover: "#F7A43F",
  Activo: "#F07B43",
  //Gris
  Neutro: "#88919E",
  TitleGris: "#717883",
  //Claro
  ClaroHover: "#FFFAF7",
  Claro: "#FFF5F0",
  //Alertas
  ErrorPasivo: "#FF655A",
  ErrorHover: "#F44336",
  ErrorHoverClaro: "#FFECEB",
  Exito: "#42C67E",
  Informacion: "#2D94B4",
  //text
  Title: "#E46525",
  TitleHover: "#FCA422",
  //lINEA
  lineaModal: "#F5A458",
  lineaTabla: "#FBD6C5",
  //circulos
  Circular: "#FEEBE2",
};

export const themes = {
  light: {
    icons: lightIcons,
    colors: lightColors,
    Lotties: LottiesIcons,
    logo: LogoIcons,
    imagen: Imagen,
  },
  dark: {
    icons: darkIcons,
    colors: darkColors,
    Lotties: LottiesIcons,
    logo: LogoIcons,
    imagen: Imagen,
  },
};
