import { FC, useState, createContext, ReactNode } from 'react';

type SidebarContext = {
  isOpen: any;
  openModal: () => void;
  closeModal: () => void;
};

// Crear el contexto con valores iniciales vacíos
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

// Definir el tipo de las props del ModalProvider, incluyendo `children`
interface ModalProviderProps {
  children: ReactNode;
}

export const SidebarProvider: FC<ModalProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <SidebarContext.Provider value={{ isOpen, openModal, closeModal }}>
      {children}
    </SidebarContext.Provider>
  );
};
