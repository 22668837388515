import React, { useEffect, useState, useContext } from "react";
import { Route, useNavigate } from "react-router-dom";

import { Buffer } from "buffer";
import dayjs from "dayjs";
import { UserContext } from "../contexts/UserContext";

const ProtectedRoute = (props: any) => {
  const { tokenUser, idTokenUser, idRuta } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    if (!tokenUser || tokenUser === undefined) {
      idRuta(undefined);
      setIsLoggedIn(false);
      return navigate("/");
    }   

    idRuta(window.location.pathname);
    idTokenUser(tokenUser);
    setIsLoggedIn(true);    
    
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};
export default ProtectedRoute;
