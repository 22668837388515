// METODO PARA CAMBIAR DE COLOR A LOS LOTTIE y LLAMAR LOS lOTTIES
import { useMemo } from "react";
import colorizeLottie from "./colorizeLottie";
import { themes } from "./Icons";

interface LottieProps {
  isDarkMode?: boolean;
}

export const useLotties = ({ isDarkMode = false }: LottieProps) => {
  const themeMode = isDarkMode ? themes.dark : themes.light;

  //COLOR NARANJA CARGA
  const LottieCarga = useMemo(() => {
    return colorizeLottie(themeMode.Lotties.cargaVerde, {
      "layers.0.shapes.0.it.1.c.k": themeMode.colors.Activo,
      "layers.3.shapes.0.it.1.c.k": themeMode.colors.Activo,
      "layers.2.shapes.0.it.1.c.k": themeMode.colors.Claro,
      "layers.1.shapes.0.it.1.c.k": themeMode.colors.Activo,
    });
  }, [themeMode]);

  //COLOR ROJO ALERTA
  const LottieAlertaRojo = useMemo(() => {
    return colorizeLottie(themeMode.Lotties.alertas, {
      "layers.0.shapes.0.it.1.c.k": themeMode.colors.ErrorHover,
      "layers.1.shapes.0.it.2.c.k": themeMode.colors.ErrorHover,
      "layers.2.shapes.0.it.2.c.k": themeMode.colors.ErrorHover,
    });
  }, [themeMode]);

  //COLOR CELESTE ALERTA
  const LottieAlertaCeleste = useMemo(
    () =>
      colorizeLottie(themeMode.Lotties.alertas, {
        // Cambia estos paths y colores según sea necesario
        "layers.0.shapes.0.it.1.c.k": "#1AA4CC",
        "layers.1.shapes.0.it.2.c.k": "#1AA4CC",
        "layers.2.shapes.0.it.2.c.k": "#1AA4CC",
      }),
    [themeMode]
  );
  //COLOR VERDE EXITO
  const LottieExitoVerde = useMemo(
    () =>
      colorizeLottie(themeMode.Lotties.exito, {
        // Cambia estos paths y colores según sea necesario
        // "layers.1.shapes.0.it.2.c.k": themeMode.colors.Activo,
        // "layers.0.shapes.0.it.1.c.k": themeMode.colors.Exito,
        // "layers.2.shapes.0.it.2.c.k": themeMode.colors.Exito,
      }),
    [themeMode]
  );

  return {
    LottieCarga,
    LottieAlertaRojo,
    LottieAlertaCeleste,
    LottieExitoVerde,
  };
};
