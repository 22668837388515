// import dayjs from "dayjs";
import { createContext, useEffect, useState } from "react";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";

// export const TokenContext = createContext();

export const TokenContext = createContext<any>(null);


// TODO Context necesita un Provider el cual se encargue de poder
// guardar y retornar la informacion que guardemos en context
export const TokenProvider = (props: any) => {
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [ruta, setRuta] = useState(localStorage.getItem("ruta"));
    const [responseLogin, setResponseLogin] = useState(localStorage.getItem("responseLogin"));
    const [loading, setLoading] = useState(false);
    const [indexRuta, setIndexRuta] = useState(Number(localStorage.getItem("indexRuta")));
    const [selectedIndexMenu, setSelectedIndexMenu] = useState(Number(localStorage.getItem("selectedIndexMenu")));


    const idToken = (idToken: any) => {
        if (idToken === null || idToken === '') {
            localStorage.removeItem("responseLogin");
            localStorage.removeItem("token");
            localStorage.removeItem("ruta");
            setToken(null);
            idLoading(false);
            //   return navigate('/');

        }
        localStorage.setItem("token", idToken);
        setToken(idToken!);
    };

    const idResponseLogin = (idResponseLogin: any) => {
        if (idResponseLogin === null || idResponseLogin === undefined) {
            localStorage.removeItem("responseLogin");
            localStorage.removeItem("token");
            localStorage.removeItem("ruta");
            setResponseLogin(null);
            idLoading(false);
            //   return navigate('/');

        }
        localStorage.setItem("responseLogin", idResponseLogin);
        setResponseLogin(idResponseLogin!);
    }

    const idRuta = (idRuta: any) => {
        if (idRuta === null || idRuta === undefined) {
            localStorage.removeItem("responseLogin");
            localStorage.removeItem("token");
            localStorage.removeItem("ruta");
            setRuta(null);
            idLoading(false);
            //   return navigate('/');

        }

        localStorage.setItem("ruta", idRuta);
        setRuta(idRuta!);

    };

    const idLoading = (idLoading: boolean) => {
        setLoading(idLoading);
    }

    const idSelectedIndexMenu = (idSelectedIndexMenu: number) => {
        localStorage.setItem("selectedIndexMenu", idSelectedIndexMenu.toString());
        setSelectedIndexMenu(idSelectedIndexMenu);
    }


    const idindexRuta = (idindexRuta: number) => {
        localStorage.setItem("indexRuta", idindexRuta.toString());
        setIndexRuta(idindexRuta);
    }

    return (
        <TokenContext.Provider
            value={{
                token,
                idToken,
                ruta,
                idRuta,
                responseLogin,
                idResponseLogin,
                loading,
                idLoading,
                selectedIndexMenu,
                idSelectedIndexMenu,
                // valorr,setvalorr
                indexRuta, idindexRuta
            }}
        >
            {props.children}
        </TokenContext.Provider>
    );
};
